<template>
  <b-col :cols="cols">
    <b-form-group>
      <label :for="id">{{ label }}</label>
      <multiselect
        :id="id"
        class="search-mode with-border"
        :show-labels="false"
        :options="filteredContracteds"
        :value="contractorName"
        :allow-empty="false"
        placeholder="Buscar"
        :disabled="readonly"
        @select="selectContracted"
        @search-change="setSearchValue"
        :internal-search="false"
        track-by="label"
      >
        <template slot="caret">
          <div class="search">
            <Search />
          </div>
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
          <div class="truncate">
            <span class="d-block">{{ option.label }}</span>
          </div>
        </template>

        <template slot="option" slot-scope="{ option }">
          <div class="custom-option">
            <div class="truncate">
              <span class="d-block">{{ option.label }}</span>
              <p>
                <span class="text-blue">{{ option.operator_code }}</span> |
                {{ option.cnes }}
              </p>
            </div>
          </div>
        </template>
        <template slot="noOptions"> Nenhuma opção </template>
        <template slot="noResult"> Nenhum resultado </template>
      </multiselect>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: 'ContractedField',
  components: {
    Search: () => import('@/assets/icons/search.svg')
  },
  props: {
    cols: {
      type: Number,
      default: 5
    },
    label: {
      type: String,
      default: 'Nome do contratado executante'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    vModel: {
      type: String,
      default: ''
    },
    validated: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    contracteds: {
      type: Array,
      required: true
    }
  },
  data() {
    return { 
      searchValue: null
    }
  },
  computed: {
    contractorName() {
      return this.contracteds.find(
        contracted => contracted.label === this.vModel
      )
    },
    filteredContracteds() {
      if(!this.searchValue) return this.contracteds
      const contractedValue = this.contracteds.filter(contracted => contracted.value.toLowerCase().includes(this.searchValue.toLowerCase()))
      return contractedValue
    }
  },
  methods: {
    selectContracted(contracted) {
      this.$emit('select-contracted', contracted)
    },
    setSearchValue(value) {
      this.searchValue = value
    }
  }
}
</script>

<style>
.text-blue {
  color: var(--blue-500) !important;
}
</style>
